import { API } from "aws-amplify";
export const GetEachWorkflowDetails = {
  data() {
    return {
      GetEachWorkflowDetailsList: [],
    };
  },
  methods: {
    async GetEachWorkflowDetailsListMethod(job_id) {
      var self = this;
      self.overlay = true;
      const path = "removealarmconfiguration";
      const myInit = {
        body: {
          command: "getWorkflowDetails",
          job_id,
        },
      };
      API.post("CHAINPORTAL", path, myInit)
        .then((response) => {
          console.log("Check The Response", response);
          if (!response.errorType) {
            self.getAllWorkflowComments = response.data.items;
            console.log("Check getAllWorkflowComments",this.getAllWorkflowComments)
          } else {
            self.getAllWorkflowComments = [];
            self.noDataText = response.errorMessage;
            console.log("noDataText",self.noDataText)
          }
        })
        .catch((error) => {
          self.overlay = false;
          console.warn(error);
        });
    },
  },
};
