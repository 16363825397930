<template>
  <div>
    <CommentsDialog :StoreObj="StoreObj" :dialogComment="dialogComment" @clicked="dialogCommentEmit" />
    <v-card class="elevation-0">
      <v-row no-gutters>
        <v-col cols="12" xs="12" sm="12" md="6">
          <v-toolbar class="elevation-0">
            <div class="heading1 PrimaryFontColor">Analysis</div>
          </v-toolbar>
        </v-col>
      </v-row>
      <v-card-text class="text-center py-0">
        <v-data-table
          dense
          :headers="alarmsHeaders"
          :items="GetAllWorkFlowsList.filter((item) => item.is_analysis_required == true)"
        >
          <template v-slot:[`item.sl_no`]="{ item }">
            <div class="fontSize">{{ GetAllWorkFlowsList.indexOf(item) + 1 }}</div>
          </template>
          <template v-slot:[`item.alarm_message`]="{ item }">
            <div class="fontSize">{{ item.alarm_message }}</div>
          </template>
          <template v-slot:[`item.job_number`]="{ item }">
            <div class="fontSize">{{ item.job_number == "NOT_ASSIGNED" ? "-" : item.job_number }}</div>
          </template>
          <template v-slot:[`item.tag_id`]="{ item }">
            <div class="fontSize">{{ item.tag_id }}</div>
          </template>
          <template v-slot:[`item.job_initiated_on`]="{ item }">
            <div class="fontSize">{{ new Date(item.job_initiated_on).toLocaleString("en-In") }}</div>
          </template>

          <template v-slot:[`item.mtbf`]="{ item }">
            <div class="fontSize">{{ convertTimeToHRMinSec(item.mtbf) }}</div>
          </template>
          <template v-slot:[`item.previous_mtbf`]="{ item }">
            <div class="fontSize">{{ convertTimeToHRMinSec(item.previous_mtbf) }}</div>
          </template>
          <template v-slot:[`item.mttr`]="{ item }">
            <div class="fontSize">{{ convertTimeToHRMinSec(item.mttr) }}</div>
          </template>
          <template v-slot:[`item.previous_mttr`]="{ item }">
            <div class="fontSize">{{ convertTimeToHRMinSec(item.previous_mttr) }}</div>
          </template>

          <template v-slot:[`item.job_status`]="{ item }">
            <div
              class="fontSize"
              :class="
                item.job_status == 'PENDING'
                  ? 'red--text'
                  : item.job_status == 'ACKNOWLEDGED'
                  ? 'blue--text'
                  : 'green--text'
              "
            >
              {{ item.job_status
              }}<span
                class="black--text font-weight-bold"
                v-if="item.has_access == false && item.is_escalated == 'ESCALATED'"
              >
                (Escalated To {{ item.escalated_to_user_email_id }})
              </span>
            </div>
          </template>
          <template v-slot:[`item.Action`]="{ item }">
            <v-btn color="green" icon @click="checkItem(item, 'COMMENT')">
              <v-icon>mdi-comment-text-outline</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import CommentsDialog from "@/components/MyAlarms/Dialogs/CommentsDialog.vue";
import { GetAllWorkFlows } from "@/mixins/GetAllWorkFlows.js";
export default {
  mixins: [GetAllWorkFlows],
  components: {
    CommentsDialog,
  },
  data: () => ({
    StoreObj: {},
    dialogComment: false,
    alarmsHeaders: [
      { text: "Sl No", value: "sl_no" },
      { text: "job No.", value: "job_number" },
      { text: "Name", value: "alarm_message" },
      { text: "Tag ID", value: "tag_id" },
      { text: "MTBF", value: "mtbf" },
      { text: "Previous MTBF", value: "previous_mtbf" },
      { text: "MTTR", value: "mttr" },
      { text: "Previous MTTR", value: "previous_mttr" },
      { text: "Action", value: "Action" },
    ],
    GetAllWorkFlowsList: [],
  }),
  mounted() {
    this.GetAllWorkFlowsListMethod(
      this.$store.getters.get_asset_object,
      this.$store.getters.get_current_user_details.user_id,
      "CLOSED"
    );
  },
  methods: {
    checkItem(item, action) {
      this.StoreObj = item;
      switch (action) {
        case "COMMENT":
          this.dialogComment = true;
          break;
      }
    },
    dialogCommentEmit() {
      this.dialogComment = false;
    },
    convertTimeToHRMinSec(time) {
      let value = time;
      this.hour = Math.floor(value / 1000 / 60 / 60);
      value -= this.hour * 1000 * 60 * 60;
      this.minute = Math.floor(value / 1000 / 60);
      value -= this.minute * 1000 * 60;
      this.seconds = Math.floor(value / 1000);
      return this.addZero(this.hour) + ":" + this.addZero(this.minute) + ":" + this.addZero(this.seconds);
    },
    addZero(i) {
      if (i < 10) {
        i = "0" + i;
      }
      return i;
    },
  },
};
</script>
