<template>
  <div>
    <v-dialog v-model="dialogComment" persistent max-width="500px">
      <v-card>
        <v-toolbar dense dark color="primary" class="elevation-0">
          Comments ({{ StoreObj.alarm_message }})
          <v-spacer></v-spacer>
          <v-btn icon @click="dialogCommentEmit()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="pb-0">
          <v-container fluid fill-height v-if="getAllWorkflowComments.length == 0">
            <v-layout align-center justify-center>
              <v-flex>
                <v-card align="center" class="pa-3" outlined>
                  <v-icon color="primary" size="50px">mdi-comment-remove-outline</v-icon>
                  <div class="font-weight-bold my-2 primary--text">No Comments Added</div>
                </v-card>
              </v-flex>
            </v-layout>
          </v-container>
          <v-card
            :height="
              getAllWorkflowComments == 0
                ? '0px'
                : getAllWorkflowComments.length > 0 && getAllWorkflowComments.length < 3
                ? '100px'
                : '200px'
            "
            class="overflow-y-auto elevation-0"
          >
            <v-card
              v-show="getAllWorkflowComments.length != 0"
              outlined
              class="elevation-1 mt-4 mx-3"
              v-for="(comment_item, idx) in $route.name == 'MyAlarms'
                ? getAllWorkflowComments.filter((comment) => comment.commented_type != 'EXPERT_COMMENT')
                : getAllWorkflowComments.filter((comment) => comment.commented_type == 'EXPERT_COMMENT')"
              :key="idx"
            >
              <div class="fontSizeSmall font-weight-bold px-2">
                {{ comment_item.commented_type.replaceAll("_", " ") }}
              </div>
              <div class="fontSize px-2">
                {{ comment_item.comment }}
              </div>
              <div class="fontSizeSmall px-2 mt-n2">
                Commented On : {{ new Date(comment_item.commented_on).toLocaleString("en-In") }}
              </div>
            </v-card>
            <v-divider class="mt-3 mx-3" v-show="getAllWorkflowComments.length != 0"></v-divider>
          </v-card>
        </v-card-text>
        <v-card-text class="px-9">
          <v-select
            dense
            outlined
            class="field_height field_label_size fontSize mt-4"
            label="Select Remark Type"
            :items="commentTypeItems"
            v-model="commented_type"
          ></v-select>
          <v-textarea
            v-if="commented_type && commented_type != ''"
            dense
            rows="2"
            outlined
            class="field_height field_label_size fontSize"
            v-model="comment"
            :label="`Add ${makeCapitalizeFunction(commented_type)}`"
          ></v-textarea>
        </v-card-text>
        <v-card-actions class="pt-0">
          <v-spacer></v-spacer>
          <v-btn small color="primary" outlined @click="dialogCommentEmit((Toggle = 1))"
            ><v-icon>mdi-close</v-icon>Cancel</v-btn
          >
          <v-btn small :disabled="comment == ''" color="primary" :loading="loading" @click="allCommonMethod()"
            ><v-icon>mdi-check</v-icon>Confirm</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { API } from "aws-amplify";
import { GetEachWorkflowDetails } from "@/mixins/GetEachWorkflowDetails.js";
export default {
  props: {
    StoreObj: Object,
    dialogComment: Boolean,
  },
  mixins: [GetEachWorkflowDetails],
  data: () => ({
    getAllWorkflowComments: [],
    comment: "",
    commented_type: "",
    loading: false,
    commentTypeItems: [],
  }),
  watch: {
    dialogComment(value) {
      if (value == true) {
        this.commented_type = "";
        this.comment = "";
        this.getAllWorkflowComments = [];
        if (this.$route.name == "Analysis" && this.StoreObj.has_expert_comment_access == true) {
          this.commentTypeItems = [{ text: "Expert Observation", value: "EXPERT_COMMENT" }];
        }
        if (this.StoreObj.job_status == "ACKNOWLEDGED") {
          this.commentTypeItems.push(
            {
              text: "Resolution Plan",
              value: "RESOLUTION",
            },
            {
              text: "Update Job Remark",
              value: "JOB_UPDATE",
            }
          );
        }
        this.GetEachWorkflowDetailsListMethod(this.StoreObj.job_id);
      }
    },
  },
  methods: {
    async allCommonMethod() {
      var mutationname = "removealarmconfiguration";
      var inputParams = {
        command: "addCommentOnWorkflow",
        organization_id: this.$store.getters.get_current_user_details.organization_id,
        user_id: this.$store.getters.get_current_user_details.user_id,
        comment_type: this.commented_type,
        job_id: this.StoreObj.job_id,
        comment: this.comment,
      };
      var self = this;
      self.loading = true;
      const path = mutationname;
      const myInit = {
        body: inputParams,
      };
      API.post("CHAINPORTAL", path, myInit)
        .then((response) => {
          if (!response.errorType) {
            this.SnackBarComponent = {
              SnackbarVmodel: true,
              SnackbarColor: "green",
              Top: true,
              SnackbarText: response.Status_Message,
            };
            this.comment = "";
            this.commented_type = "";
            this.GetEachWorkflowDetailsListMethod(this.StoreObj.job_id);
            this.loading = false;
          } else {
            this.loading = false;
            self.noDataText = response.errorMessage;
          }
        })
        .catch((error) => {
          this.loading = false;
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            Top: true,
            SnackbarText: error.errors[0].message,
          };
        });
    },
    makeCapitalizeFunction(commented_type) {
      return this.commentTypeItems.filter((comment) => comment.value == commented_type)[0].text;
    },
    dialogCommentEmit() {
      this.$emit("clicked");
    },
  },
};
</script>
