var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CommentsDialog',{attrs:{"StoreObj":_vm.StoreObj,"dialogComment":_vm.dialogComment},on:{"clicked":_vm.dialogCommentEmit}}),_c('v-card',{staticClass:"elevation-0"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"12","md":"6"}},[_c('v-toolbar',{staticClass:"elevation-0"},[_c('div',{staticClass:"heading1 PrimaryFontColor"},[_vm._v("Analysis")])])],1)],1),_c('v-card-text',{staticClass:"text-center py-0"},[_c('v-data-table',{attrs:{"dense":"","headers":_vm.alarmsHeaders,"items":_vm.GetAllWorkFlowsList.filter(function (item) { return item.is_analysis_required == true; })},scopedSlots:_vm._u([{key:"item.sl_no",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"fontSize"},[_vm._v(_vm._s(_vm.GetAllWorkFlowsList.indexOf(item) + 1))])]}},{key:"item.alarm_message",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"fontSize"},[_vm._v(_vm._s(item.alarm_message))])]}},{key:"item.job_number",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"fontSize"},[_vm._v(_vm._s(item.job_number == "NOT_ASSIGNED" ? "-" : item.job_number))])]}},{key:"item.tag_id",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"fontSize"},[_vm._v(_vm._s(item.tag_id))])]}},{key:"item.job_initiated_on",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"fontSize"},[_vm._v(_vm._s(new Date(item.job_initiated_on).toLocaleString("en-In")))])]}},{key:"item.mtbf",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"fontSize"},[_vm._v(_vm._s(_vm.convertTimeToHRMinSec(item.mtbf)))])]}},{key:"item.previous_mtbf",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"fontSize"},[_vm._v(_vm._s(_vm.convertTimeToHRMinSec(item.previous_mtbf)))])]}},{key:"item.mttr",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"fontSize"},[_vm._v(_vm._s(_vm.convertTimeToHRMinSec(item.mttr)))])]}},{key:"item.previous_mttr",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"fontSize"},[_vm._v(_vm._s(_vm.convertTimeToHRMinSec(item.previous_mttr)))])]}},{key:"item.job_status",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"fontSize",class:item.job_status == 'PENDING'
                ? 'red--text'
                : item.job_status == 'ACKNOWLEDGED'
                ? 'blue--text'
                : 'green--text'},[_vm._v(" "+_vm._s(item.job_status)),(item.has_access == false && item.is_escalated == 'ESCALATED')?_c('span',{staticClass:"black--text font-weight-bold"},[_vm._v(" (Escalated To "+_vm._s(item.escalated_to_user_email_id)+") ")]):_vm._e()])]}},{key:"item.Action",fn:function(ref){
                var item = ref.item;
return [_c('v-btn',{attrs:{"color":"green","icon":""},on:{"click":function($event){return _vm.checkItem(item, 'COMMENT')}}},[_c('v-icon',[_vm._v("mdi-comment-text-outline")])],1)]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }