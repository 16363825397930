import { API } from "aws-amplify";
export const GetAllWorkFlows = {
  data() {
    return {
      getAllWorkflowComments: [],
    };
  },
  methods: {
    async GetAllWorkFlowsListMethod(asset, user_id, job_status) {
      var self = this;
      self.overlay = true;
      const path = "removealarmconfiguration";
      const myInit = {
        body: {
          command: "listWorkflows",
          organization_id: asset.organization_id,
          asset_id: asset.asset_id,
          user_id,
          job_status: job_status,
        },
      };
      API.post("CHAINPORTAL", path, myInit)
        .then((response) => {
          if (self.$route.name != "Dashboard") {
            self.overlay = false;
          }
          if (!response.errorType) {
            self.GetAllWorkFlowsList = response.data.items;
          } else {
            self.GetAllWorkFlowsList = [];
            self.noDataText = response.errorMessage;
          }
        })
        .catch((error) => {
          self.overlay = false;
          console.warn(error);
        });
    },
  },
};
